.popup {
    width: 100%;
    height: 100%;
}

.popup-global-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
}

.popup-dialog {
	position: relative;
	background: #323232 ;
	overflow: hidden;
	-webkit-box-shadow: 0 0 10pt 5pt rgba(0, 0, 0, 0.3);
	        box-shadow: 0 0 10pt 5pt rgba(0, 0, 0, 0.3);
	min-width: 300px;
}

.popup-dialog__content {
	padding: 40px 40px 20px;
	color: #fff;
	font-size: 14px;
}

.popup-dialog__content-without-padding {
	margin: -20px -40px -10px;
}

.popup-dialog__content-text {
	line-height: 1.3;
}

.popup-dialog-footer {
	padding: 25px 40px 35px;
}

.popup-dialog-footer__m-alignment-left {
	text-align: left;
}

.popup-dialog-footer__m-alignment-right {
	text-align: right;
}

.popup-dialog-footer__m-alignment-center {
	text-align: center;
}

.popup-dialog-footer .Button {
	margin-right: 20px;
  width:100px;
}

.popup-dialog-footer .Button:last-child {
	margin-right: 0;
}

.popup-dialog-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	    flex-direction: row;
	-ms-flex-wrap: nowrap;
	    flex-wrap: nowrap;
	-ms-flex-align: center;
	    align-items: center;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	overflow: hidden;
	padding: 8px;
	background: #3d3d3d ;
	color: #fff;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	font-size: 14px;
}

.popup-dialog-header__title {
	-ms-flex: 1 1 auto;
	    flex: 1 1 auto;
	display: inline-block;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
	-o-text-overflow: ellipsis;
	   text-overflow: ellipsis;
  font-size: 18px;
}

.popup-dialog-header__icon {
	display: inline-block;
	margin-right: 10px;
	width: 20px;
	height: 20px;
}

.popup-dialog-header__icon__m-success {
	/*background: url('../images/success.svg');*/
}

.popup-dialog-header__icon__m-error {
	/*background: url('../images/error.svg');*/
}

.popup-dialog-header__icon__m-list {
	/*background: url('../images/list.svg');*/
}
