@import url('./fonts.css');

*{
	margin:0px;
	font-family: 'Proxima Nova', Georgia, sans-serif;
  font-size: 14px;
}

html {
  font-family: 'Proxima Nova', Georgia, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #12171a;
}
