.ce-paragraph {
    line-height: 1.6em;
    outline: none;
}
.ce-paragraph--right {
    text-align: right;
}
.ce-paragraph--center {
    text-align: center;
}
.ce-paragraph--left {
    text-align: left;
}

.ce-paragraph--justify {
    text-align: justify;
}

.ce-paragraph[data-placeholder]:empty::before{
  content: attr(data-placeholder);
  color: #707684;
  font-weight: normal;
  opacity: 0;
}

.ce-paragraph:not(:empty)::after{
    content: '';          /* Necessary for the pseudo-element to be generated */
    position: absolute;   /* Absolute positioning */
    top: 13px;             /* Center the circle vertically */
    left: -10px;            /* Center the circle horizontally */
    width: 8px;          /* Match the width of the text + padding */
    height: 8px;         /* Match the height of the text + padding */
    background-color: #A9A7A7;  /* Your desired circle color */
    border-radius: 50%;      /* Makes it circular */
    padding-left: 4px;
    padding-right: 4px;
    content: '';          /* Necessary for the pseudo-element to be generated */
    position: absolute;   /* Absolute positioning */
    top: 15px;             /* Center the circle vertically */
    left: -10px;            /* Center the circle horizontally */
    width: 0px;          /* Match the width of the text + padding */
    height: 4px;         /* Match the height of the text + padding */
    background-color: #405159 !important;  /* Your desired circle color */
    border-radius: 50%;      /* Makes it circular */
    padding-left: 0px;
    padding-right: 4px;
}

.ce-category-block--caries:not(:empty)::after{
    background-color: #DC4E41;  /* Your desired circle color */
}

.ce-category-block--gums:not(:empty)::after{
    background-color: #ffa500;  /* Your desired circle color */
}

.ce-category-block--habits:not(:empty)::after{
    background-color: #daff00;  /* Your desired circle color */
}   

.ce-category-block--ortho:not(:empty)::after{
    background-color: #405159;  /* Your desired circle color */
}

.ce-category-block--color:not(:empty)::after{
    background-color: #367FFF;  /* Your desired circle color */
}

.ce-category-block--general:not(:empty)::after{
    background-color: #367FFF;  /* Your desired circle color */
}

.ce-category-block--others:not(:empty)::after{
    background-color: #367FFF;  /* Your desired circle color */
}


/** Show placeholder at the first paragraph if Editor is empty */
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before {
  opacity: 1;
}

.codex-editor--toolbox-opened .ce-block:first-child .ce-paragraph[data-placeholder]:empty::before,
.codex-editor--empty .ce-block:first-child .ce-paragraph[data-placeholder]:empty:focus::before {
  opacity: 0;
}

.ce-paragraph p:first-of-type{
    margin-top: 0;
}

.ce-paragraph p:last-of-type{
    margin-bottom: 0;
}


.svg-icon {
    width: 1em;
    height: 1em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
    fill: #4691f6;
}

.svg-icon circle {
    stroke: #4691f6;
    stroke-width: 1;
}

.cdx-toothnumbers{
    display: inline-flex;
}

.cdx-toothnumbers .cdx-tooth:first-child {
    margin-left: 8px;
}

.cdx-tooth {
    text-align: center;
    position: relative;
    z-index:1;
    color: #273238;
    margin-right: 5px;
    margin-left: 5px;
    padding-left: 2px;
    padding-right: 2px;
    min-width: 25px;
}

.cdx-tooth::after {
    content: '';          /* Necessary for the pseudo-element to be generated */
    position: absolute;   /* Absolute positioning */
    top: 50%;             /* Center the circle vertically */
    left: 50%;            /* Center the circle horizontally */
    width: 25px;          /* Match the width of the text + padding */
    height: 24px;         /* Match the height of the text + padding */
    background-color: #efefef;  /* Your desired circle color */
    border-radius: 50%;      /* Makes it circular */
    padding-left: 4px;
    padding-right: 4px;
    transform: translate(-50%, -50%); /* Ensure it's centered with the text */
    z-index: -1;  
}

.ce-category-block--caries  .cdx-tooth::after {
    margin-right: 5px;
    background-color: #DC4E41;
}
.ce-category-block--caries  .cdx-tooth{
    color: #fff;
}

.ce-category-block--gums  .cdx-tooth::after {
    background-color: #ffa500;
}

.ce-category-block--habits  .cdx-tooth::after {
    background-color: #daff00;
}

.ce-category-block--ortho  .cdx-tooth::after {
    background-color: #405159;
}
.ce-category-block--ortho  .cdx-tooth {
    color: #fff;
}

.ce-category-block--color  .cdx-tooth::after {
    background-color: #367FFF;
    color: #fff;
}
.ce-category-block--color  .cdx-tooth {
    color: #fff;
}

.ce-category-block--general  .cdx-tooth::after {
    background-color: #367FFF;
    color: #fff;
}
.ce-category-block--others  .cdx-tooth {
    color: #fff;
}
.ce-category-block--others  .cdx-tooth::after {
    background-color: #367FFF;
    color: #fff;
}