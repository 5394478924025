body {
  margin: 0;
  font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.framePreviewTooltip.show{
  opacity:1!important;
}

h1{
  color: #273238;
  font-family: "Proxima Nova";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}

h2{
  color: #405159;
  font-family: "Proxima Nova";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}

h3 {
  color: #273238;
  font-family: "Proxima Nova";
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}

h4{
  color: #000;
  font-family: "Proxima Nova";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

h5{
  color: #000;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

h6 {
  color: #7A8F99;
  font-family: "Proxima Nova";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  /*border: 6px solid rgba(0, 0, 0, 0.2);*/
}
