.ce-block {
    padding-bottom: 0.3em;
}

.codex-editor--narrow .codex-editor__redactor{
    margin-right: 30px;
}

.ce-toolbar__actions--opened .ce-toolbar__plus{
    opacity: 0;
    display: none;
}

.ce-toolbar__settings-btn {
    display: none !important;
}

.ce-toolbar__plus svg{
    height: 20px;
    width: 20px;
}